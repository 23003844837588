import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
import config from '../../config';
import plpdf from '../assets/pdfs/Projects.pdf';
import respdf from '../assets/pdfs/Resume.pdf'; 
import jobData from '../../content/jobs.json';
import eduData from '../../content/education.json';
import certData from '../../content/certifications.json';
import skillData from '../../content/skills.json';

const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        <ul className="actions special">
          <li>
            <Scroll type="id" element="one">
              <a href="/#" className="button">
                Explore
              </a>
            </Scroll>
          </li>
        </ul>
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
          Learn More
        </a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
            Wohin ich möchte
          </h2>
          <p>
            Mein Antrieb ist der Spaß am Lösen von Problemen im Team und in meinem technologischen Umfeld: Der Java-Entwicklung
            mit modernen Frameworks und der zugehörigen Software-Architektur.
          </p>
          <p>
          „Die Neugier steht immer an erster Stelle eines Problems, das gelöst werden will.“ <br/>
         <div className='align-right'><i>—  Galileo Galilei</i></div></p>
        </header>
        <ul className="icons major">
          <li>
            <span className="icon fa-handshake major style1">
              <span className="label">Vertrauen</span>
            </span>
          </li>
          <li>
            <span className="icon fa-heart major style2">
              <span className="label">Engagement</span>
            </span>
          </li>
          <li>
            <span className="icon fa-star major style1">
              <span className="label">Freiheit</span>
            </span>
          </li>
        </ul>
      </div>
    </section>

    <section id="two" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <img src={pic1} alt="" />
        </div>
        <div className="content">
          <h2>
            Berufspraxis
          </h2>
          <ul className="alt">
            {jobData.slice(0,4).map((value, index) => {
              return <li key={index}>
                <header>
                  {value.beginning}<h4>{value.company}</h4>
                  <p>{value.title}</p>
                </header>
                { ("tasks" in value) ? 
                  <ul className="small">
                    {value.tasks.map((task) => {
                      return <li>{task}</li>
                    })}
                  </ul> : ''}
              </li>;
            })}
            <li>
              <header>
                <h4>und weitere</h4>
              </header>
              <p></p>
            </li>
          </ul>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic2} alt="" />
        </div>
        <div className="content">
          <h2>
            Zertifizierungen
          </h2>
          <ul className="alt">
            {certData.map((value) => {
              return <li>
                  {value.date}&nbsp;<br/>{value.title}</li>;
            })}
          </ul>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic3} alt="" />
        </div>
        <div className="content">
          <h2>
            Studium&nbsp;
            <br />
            und Schulausbildung
          </h2>
          <ul className="alt">
            {eduData.map((value) => {
              return <li>
                  {value.date}&nbsp;<br/>{value.title}</li>;
            })}
          </ul>
        </div>
      </section>
    </section>

    <section id="three" className="wrapper style5 special">
      <div className="inner">
        <header className="major">
          <h2>Was ich  bieten kann</h2>
        </header>
        <ul className="features">
          {skillData.map((value) => {
            return <li className={value.icon}>
                <h3>{value.header}</h3>
                  {value.descriptions.map((description) => {
                    return <p>{description}</p>;
                  })}
              </li>;
          })}
        </ul>
      </div>
    </section>

    <section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>Möchten Sie mehr wissen?</h2>
          <p>
            Auf den nebenstehenden Dokumenten, in meiner <Link to="/Projects">Projekliste</Link> oder
            meinem <Link to="/Resume">Lebenslauf</Link> finden
            Sie weitere Informationen.
          </p>
        </header>
        <ul className="actions stacked">
          <li>
            <a href={plpdf} className="button fit">
              Projektliste
            </a>
          </li>
          <li>
            <a href={respdf} className="button fit">
              Lebenslauf
            </a>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
