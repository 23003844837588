module.exports = {
  siteTitle: 'Andreas Kreisel - Software Architect and DevOps Enthusiast', // <title>
  siteDescription: 'Resume, Projectlist and further Information of Andreas Kreisel, a Java Backend Software Architect and DevOps Enthusiast',
  siteAuthor: 'Andreas Kreisel',
  siteKeywords: 'java, software architect, backend, resume, cv, freelancer',
  manifestName: 'Andreas Kreisel',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-spectral/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Andreas Kreisel',
  subHeading: 'Software Architect and DevOps Enthusiast ',
};
 